import { IFormulario } from "./formulario";

export enum TypeConfigHome {
    Weather = "WEATHER",
    SocialNetworks = "SOCIAL_NETWORKS",
    Logo = "LOGO"
}

export const TYPES_CONFIG_HOME: IFormulario[] = [
    {
        id: TypeConfigHome.Weather,
        descripcion: "Clima"
    },
    {
        id: TypeConfigHome.SocialNetworks,
        descripcion: "Redes sociales"
    },
    {
        id: TypeConfigHome.Logo,
        descripcion: "Logotipos"
    }
];
