<form
  [formGroup]="contactoForm"
  class="contact-form justify-content-center"
  *ngIf="!enviando"
>
  <div class="contact-column">
    <div class="contact-column row">
      <div class="col-md-4 col-sm-12 my-2" *ngFor="let input of inputComponent">
        <!-- inputs  -->
        <label for="username" required>{{ input.label }} </label>
        <input
          [type]="input.inputType"
          [id]="input.inputId"
          [class]="input.inputClass"
          [placeholder]="input.inputPHolder"
          [formControlName]="input.formControlName"
          [maxlength]="input.maxLength"
        />
        <div
          class="error"
          *ngIf="submitted && contactoForm.get(input.formControlName)?.errors"
        >
          Ingresa un valor válido
        </div>
      </div>
    </div>

    <div class="contact-menssaje row">
      <div class="col">
        <label for="username">{{ textareaComponent.label }}</label>
        <!-- Description-->
        <textarea
          [class]="textareaComponent.TAClass"
          [rows]="textareaComponent.TArows"
          formControlName="descripcion"
        ></textarea>

        <div
          class="error"
          *ngIf="submitted && contactoForm.get('descripcion')?.errors"
        >
          Ingresa un valor válido
        </div>
      </div>
    </div>

    <!-- </div> -->
    <div class="text-center mt-3">
      <button-content
        [buttonComp]="infoButt"
        (click)="sendEmail()"
      ></button-content>
    </div>
  </div>
</form>
<div class="loader" *ngIf="enviando">
  <mat-spinner></mat-spinner>
</div>
