<div class="weather-container" *ngIf="this.weatherData.length">
    <h2>Clima en {{ this.description }}, {{ weatherData[0].sys.country }}</h2>
    <p><strong>Temperatura:</strong> {{ weatherData[0].main.temp }} ºC
        <i class="fas fa-thermometer-quarter ml-3" style="color: orange;"></i>
    </p>
    <p><strong>Clima:</strong> {{ weatherData[0].weather[0].description }}
        <i class="fas fa-cloud ml-3" style="color: lightblue;"></i>
    </p>
    <p><strong>Humedad:</strong> {{ weatherData[0].main.humidity }} %
        <i class="fas fa-tint ml-3" style="color: blue;"></i>
    </p>
    <p><strong>Velocidad del Viento:</strong> {{ weatherData[0].wind.speed }} m/s
        <i class="fas fa-wind ml-3" style="color: lightgray;"></i>
    </p>
    <p><strong>Nubosidad:</strong> {{ weatherData[0].clouds.all }} %
        <i class="fas fa-cloud ml-3" style="color: lightblue;"></i>
    </p>
    <p><strong>Visibilidad:</strong> {{ weatherData[0].visibility }} m
        <i class="fas fa-eye ml-3" style="color: darkblue;"></i>
    </p>
</div>