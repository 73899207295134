import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/Services/Api/api.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
})
export class FormComponent implements OnInit {
  public submitted = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  enviando = false;

  public contactoForm: FormGroup;
  public nombre = new FormControl('', [
    Validators.required,
    Validators.maxLength(60),
  ]);
  public apellido = new FormControl('', [
    Validators.required,
    Validators.maxLength(60),
  ]);

  public email = new FormControl('', [
    Validators.required,
    Validators.email,
    Validators.maxLength(100),
  ]);

  public descripcion = new FormControl('', [
    Validators.required,
    Validators.maxLength(250),
  ]);

  inputComponent = [
    {
      label: 'Nombre',
      inputType: 'text',
      inputId: 'nombre',
      inputClass: 'form-control',
      formControlName: 'nombre',
      inputPHolder: '',
      maxLength: 60,
    },
    {
      label: 'Apellido',
      inputType: 'text',
      inputId: 'apellido',
      inputClass: 'form-control',
      formControlName: 'apellido',
      inputPHolder: '',
      maxLength: 60,
    },
    {
      label: 'Correo electrónico',
      inputType: 'text',
      inputId: 'email',
      inputClass: 'form-control',
      formControlName: 'email',
      inputPHolder: '',
      maxLength: 100,
    },
  ];

  textareaComponent = {
    label: 'Descripción',
    TAClass: 'form-control',
    formControlName: 'descripcion',
    TArows: '8',
    maxLength: 250,
  };

  infoButt = {
    buttClass: 'btn-form btn-primary back',
    buttType: 'submit',
    buttValue: 'Enviar',
  };

  constructor(private _apiService: ApiService, private _snackBar: MatSnackBar) {
    this.contactoForm = new FormGroup({
      nombre: this.nombre,
      apellido: this.apellido,
      email: this.email,
      descripcion: this.descripcion,
    });
  }
  ngOnInit(): void {}

  sendEmail() {
    this.submitted = true;

    if (this.contactoForm.valid) {
      this.enviando = true;
      this._apiService
        .post('email', {
          nombre: this.contactoForm.get('nombre')?.value,
          apellido: this.contactoForm.get('apellido')?.value,
          correo: this.contactoForm.get('email')?.value,
          descripcion: this.contactoForm.get('descripcion')?.value,
        })
        .then(() => {
          this.contactoForm.reset();
          this.contactoForm.updateValueAndValidity();
          this.contactoForm.markAllAsTouched();
          this.ngOnInit();
          this.submitted = false;
          this.enviando = false;
          this._snackBar.open('Se ha enviado el mensaje correctamente', 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        });
    }
  }
}
