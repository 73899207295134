import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ApiService } from 'src/app/Services/Api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit, OnDestroy {
  cardsData: any[] = [];
  subscription: Subscription | undefined;
  dataGetWeather: any[] = [];

  constructor(private _apiService: ApiService) {
    _apiService.get('noticia').then((data) => {
      this.cardsData = [
        ...data.noticias.map((noticia: any) => ({
          href: '/',
          src: `${environment.backendURL}/publico/imagenes/1/${noticia.imagen}`,
          altT: 'Card image cap',
          heightInput: '',
          widthInput: '',
          class: 'card-img-top noticia',
          date: noticia.fechaPublicacion,
          title: noticia.nombre,
          text: noticia.resumen,
          id: noticia.id,
        })),
      ].slice(0, 4);
    });
  }

  ngOnInit(): void {
    this.dataGetWeather = this.dataGetWeatherInit;
    if (this.subscription) {
      this.subscription = interval(30 * 60 * 1000)
        .subscribe(() => {
          this.dataGetWeather = [];
          setTimeout(() => {
            this.dataGetWeather = this.dataGetWeatherInit;
          }, 10);
        });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  dataHeader = {
    classTitle: 'blue',
    title: 'Noticias de interés',
    classSubtitle: 'small',
    subtitle: 'Entérate de lo que está pasando en Autovía Neiva - Girardot',
  };

  dataHeaderSocialNetwork = {
    classTitle: 'blue',
    title: 'Novedades',
    classSubtitle: 'small',
    subtitle: 'Entérate de lo que está pasando en Autovía Neiva - Girardot',
  };

  dataHeaderWeatherCondition = {
    classTitle: 'blue',
    title: 'Estado del tiempo',
    classSubtitle: 'small',
    subtitle: 'Entérate del estado del tiempo con Autovía Neiva - Girardot',
  };

  dataGetWeatherInit: any[] = [
    {
      name: "METEO1_PK24+700_UF2A_PEAJE-NEIVA",
      description: "Neiva",
      iat: "2.977854465656328",
      ion: "-75.30733478750534",
      appid: "039496041ec8be6ab9c9daa0ec332aa6"
    },
    {
      name: "METEO2_PK75+044_UF3_PEAJE-PATA",
      description: "Pata",
      iat: "3.394349810197478",
      ion: "-75.20377511790122",
      appid: "039496041ec8be6ab9c9daa0ec332aa6"
    },
    {
      name: "METEO3_PK181+000_UF5_PEAJE-FLANDES",
      description: "Flandes",
      iat: "4.194675268388428",
      ion: "-74.8609491312072",
      appid: "039496041ec8be6ab9c9daa0ec332aa6"
    }
  ]

  partners = [
    {
      src: '../../../../assets/images/Bt_Ministerio.png',
      target: '_blank',
      targetURL: 'https://www.mintransporte.gov.co/',
      altT: 'minTransporte',
      heightInput: '',
      widthInput: '130px',
      class: '',
    },
    {
      src: '../../../../assets/images/Btn_Autovia_.png',
      target: '_self',
      targetURL: '',
      altT: 'autovia',
      heightInput: '',
      widthInput: '130px',
      class: '',
    },
    {
      src: '../../../../assets/images/Bt_Simit.png',
      target: '_blank',
      targetURL: 'https://consulta.simit.org.co/Simit/',
      altT: 'simit',
      heightInput: '',
      widthInput: '130px',
      class: '',
    },
    {
      src: '../../../../assets/images/Bt_RUNT.png',
      target: '_blank',
      targetURL: 'https://www.runt.com.co/',
      altT: 'Runt',
      heightInput: '',
      widthInput: '130px',
      class: '',
    },
    {
      src: '../../../../assets/images/Btn_GobiernoEnLinea.png',
      target: '_blank',
      targetURL:
        'https://estrategia.gobiernoenlinea.gov.co/623/w3-channel.html',
      altT: 'GOVenLinea',
      heightInput: '',
      widthInput: '130px',
      class: '',
    },
  ];
}
