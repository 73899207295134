import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { delay } from 'rxjs/operators';
import { ChangeDataService } from 'src/app/Services/ChangeData/changeData.service';
import { PagerService } from 'src/app/Services/Pager/pager.service';

@Component({
  selector: 'app-data-importes',
  templateUrl: './data-importes.component.html',
  styleUrls: ['./data-importes.component.css']
})
export class DataImportesComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() defaultTittle!: string;

  pagedItems: any[] = []; // paged items
  newData: any[] = [];
  pager: any = {}; // pager objeto
  links = ['Importes', null];
  inputText = 'Buscar';

  subtitle =
    'A continuación, encontrará las distintas tarifas cobradas por la concesión';

  constructor(
    private pagerService: PagerService,
    private changeDataService: ChangeDataService
  ) { }

  ngOnInit(): void {
    this.changeDataService.onChangedTittle$.pipe(delay(0)).subscribe((x) => {
      this.links[1] = x;
    });
    this.links[1] = this.defaultTittle;
  }

  // En cuanto haya cambios actualizar a la primera página
  ngOnChanges(changes: SimpleChanges) {
    if (this.data) {
      this.setPage(1);
    }
  }

  setPage(page: number) {
    // obtener el objeto pager desde el servicio
    this.pager = this.pagerService.getPager(this.data.length, page, 10);

    // obtener la página de items actual
    this.pagedItems = this.data.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.newData = this.pagedItems;
  }

  onResult(data: any) {
    this.newData = data;
  }
}
