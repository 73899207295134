<div class="content d-flex row">
  <div class="contenedor">
    <div class="carousel">
      <div class="carousel__contenedor">
        <button
          aria-label="Anterior"
          class="carousel__anterior"
          (click)="prevSlide()"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <div class="carousel__lista d-flex flex-row justify-content-around">
          <div
            class="carousel__elemento"
            *ngFor="let icon of showIcons; let i = index"
          >
            <!--Iconos-->
            <a
              href="{{ icon.url }}"
              target="_blank"
              rel="noopener noreferrer"
              title=""
            >
              <i [ngClass]="icon.icono" class="icono-azul"></i
            ></a>
            <p class="text-img">{{ icon.nombre }}</p>
          </div>
        </div>

        <button
          aria-label="Siguiente"
          class="carousel__siguiente"
          (click)="nextSlide()"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
