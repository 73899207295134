<div class="header-container">
  <div class="col-md-8 col-sm-12 height-defined">
    <ul class="d-flex flex-row breadcrumbs">
      <li class="breadcrumb-item blue-bold" *ngFor="let link of links">
        {{ link }}
      </li>
    </ul>
    <label-data [label]="subtitle" class="text-small"></label-data>
  </div>
  <div class="search col-md-4 col-sm-12 height-defined"></div>
</div>
<div
  class="d-flex flex-column gray-border panel-formatos inline-html"
  [innerHtml]="data[0].content"
></div>
