import { Component, OnInit } from '@angular/core';
import { ServicesOrganismService } from '../services-transversal/services-organism.service';
import { Datum } from './models/instagram.models';

@Component({
  selector: 'app-instagram-feed',
  templateUrl: './instagram-feed.component.html',
  styleUrls: ['./instagram-feed.component.css']
})
export class InstagramFeedComponent implements OnInit {
  posts: Datum[] = [];
  tweets: any[] = [];

  constructor(private servicesOrganismService: ServicesOrganismService) { }

  ngOnInit(): void {
    this.servicesOrganismService.getInstagramPosts().subscribe(
      (data) => {
        this.posts = data.data;
      },
      (error) => {
        console.error('Error fetching Instagram posts', error);
      }
    );
  }

}
