<!-- <div class="toolbar d-flex justify-content-between" role="banner"> -->
<nav class="navbar navbar-expand-lg navbar-dark">
  <a class="navbar-brand" href="/home" target="_blank">
    <image-link [imgInfo]="imgInfoV" [ngClass]="'logo'"></image-link>
  </a>
  <button class="navbar-toggler" (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngClass]="{ show: navbarOpen }">
    <div class="tittle-responsive">
      <navbar-list class="margin-around" [links]="links" targetType="_self"></navbar-list>
    </div>
  </div>
  <a class="img-right">
    <!-- <image-link [imgInfo]="imgInfoS" class="mr-3"></image-link> -->
    <div class="iconos_footer_dos d-flex flex-wrap d-block">
      <image-link *ngFor="let icon of iconList" [imgInfo]="icon"></image-link>
    </div>
  </a>
</nav>