import { Component } from '@angular/core';
import { ApiService } from 'src/app/Services/Api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carrusel-imgs',
  templateUrl: './carrusel-imgs.component.html',
  styleUrls: ['./carrusel-imgs.component.css'],
})
export class CarruselImgsComponent {
  images: string[];

  constructor(private _apiService: ApiService) {
    this.images = [];
    _apiService.get('banner').then((data) => {
      this.images = [
        ...data.banners.map(
          (banner: any) =>
            `${environment.backendURL}/publico/imagenes/8/${banner.nombre}`
        ),
      ];
    });
  }
}
