import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.css']
})
export class ModalInfoComponent {
  @Input() images: any[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  closeModal() {
    this.activeModal.close(); // Cierra el modal
  }
}
