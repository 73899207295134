<body>
  <div class="accesibility-bar">
    <span class="material-icons" (click)="contrast()">contrast</span>
    <span>
      <div (click)="changeFont('+')" class="text-icon" id="font-up">A+</div>
    </span>
    <span>
      <div (click)="changeFont('-')" class="text-icon" id="font-down">A-</div>
    </span>
  </div>
</body>
