import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServicesOrganismService {
  private accessToken = 'IGQWROdDRNTkJWbzNad2FWWFVRZAWRBVm12YkZA6dWtsOWd1aUhVWWpINWlSZAkppLWN5THpYcml6aXZADeTV3YXZATMzJWOExfLWFVZAjhvX3N6QXoybFJLN1p6T1FQaHVjeHhSY1pKY2FjdWlKWHlyQXM1SUpkaVhVV1UZD';
  private apiUrl = 'https://api.twitter.com/2/tweets';

  constructor(private http: HttpClient) { }

  public getInstagramPosts(): Observable<any> {
    const url = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp&access_token=${this.accessToken}`;
    return this.http.get<any>(url);
  }

  public getTweets() {
    const headers = {
      Authorization: `AAAAAAAAAAAAAAAAAAAAADZdwQEAAAAABUahCGzxmHYQvZc%2Fwf93sZJZpkc%3D3lMVe553e4HKa4NFg0tSGl62DktswuPHeYlaMSxOhy9ECu4gOQ`,
    };
    return this.http.get(this.apiUrl, { headers });
  }
}
