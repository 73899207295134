import { TypeConfigHome } from "src/app/patterns/models/constantes-home";

export interface IHomeParams {
    id: string;
    codigoType: TypeConfigHome;
    nombre: string;
    parametros: string; // Json
    imagen?: string;
    nombreArchivos?: string;
    activo:boolean;
}

export interface IHomeParamsConfig{
    homesConfig: IHomeParams[];
}

export enum SocialNetworkEnum {
    Facebook = "FACEBOOK",
    Instagram = "INSTAGRAM",
    Twitter = "TWITTER"
}