<div class="contenedor row">
  <div class="col col-imagen">
    <img src="../../../../assets/images/Btn_Autovia_.png" alt="" />
    <h1 class="construccion-tittle">En construcción</h1>
  </div>
  <div class="col">
    <i class="fas fa-info-circle"></i>
    <h4>La página a la que intentas ingresar está en construcción</h4>
    <button  [routerLink]="['/']">Volver al inicio</button>
  </div>
</div>
