import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ApiService } from 'src/app/Services/Api/api.service';
import { environment } from 'src/environments/environment';
import { IWeatherStation } from './models/weather.interface';
import { IHomeParams, IHomeParamsConfig, SocialNetworkEnum } from './models/home-params.interface';
import { TypeConfigHome } from '../../models/constantes-home';
import { ILogo } from './models/logo.interface';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit, OnDestroy {
  dataGetWeatherInit: IWeatherStation[] = [];
  dataGetWeather: IWeatherStation[] = [];
  subscription: Subscription | undefined;
  typeConfigHome = TypeConfigHome;
  cardsData: any[] = [];
  partners: ILogo[] = [];

  facebookEnabled?: IHomeParams;
  instagramEnabled?: IHomeParams;
  twitterEnabled?: IHomeParams;
  socialNetworksOptions = SocialNetworkEnum;

  constructor(private _apiService: ApiService) {
  }

  ngOnInit(): void {
    this.getNews();
    this.getHomeConfigs();
    this.dataGetWeather = this.dataGetWeatherInit;
    if (this.subscription) {
      this.subscription = interval(30 * 60 * 1000)
        .subscribe(() => {
          this.dataGetWeather = [];
          setTimeout(() => {
            this.dataGetWeather = this.dataGetWeatherInit;
          }, 10);
        });
    }
  }

  // Home
  private getHomeConfigs() {
    this._apiService.get('config-home').then((data: IHomeParamsConfig) => {
      // Weather
      this.dataGetWeatherInit = data.homesConfig
        .filter((itm) => itm.codigoType === this.typeConfigHome.Weather)
        .map((item: IHomeParams) => {
          return JSON.parse(item.parametros);
        });
      this.dataGetWeather = this.dataGetWeatherInit;

      // Social
      this.facebookEnabled = data.homesConfig.find((item) => item.nombre.toUpperCase() === this.socialNetworksOptions.Facebook);
      this.instagramEnabled = data.homesConfig.find((item) => item.nombre.toUpperCase() === this.socialNetworksOptions.Instagram);
      this.twitterEnabled = data.homesConfig.find((item) => item.nombre.toUpperCase() === this.socialNetworksOptions.Twitter);

      // Logo
      this.partners = data.homesConfig
        .filter((itm) => itm.codigoType === this.typeConfigHome.Logo)
        .map((item: IHomeParams) => {
          const parametros = {
            ...JSON.parse(item.parametros),
            ...item
          };
          return {
            ...parametros,
            src: `${environment.backendURL}/publico/imagenes/11/${parametros.imagen}`
          }
        });
    });
  }

  // News
  private getNews() {
    this._apiService.get('noticia').then((data) => {
      this.cardsData = [
        ...data.noticias.map((noticia: any) => ({
          href: '/',
          src: `${environment.backendURL}/publico/imagenes/1/${noticia.imagen}`,
          altT: 'Card image cap',
          heightInput: '',
          widthInput: '',
          class: 'card-img-top noticia',
          date: noticia.fechaPublicacion,
          title: noticia.nombre,
          text: noticia.resumen,
          id: noticia.id,
        })),
      ].slice(0, 4);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  dataHeader = {
    classTitle: 'blue',
    title: 'Noticias de interés',
    classSubtitle: 'small',
    subtitle: 'Entérate de lo que está pasando en Autovía Neiva - Girardot',
  };

  dataHeaderSocialNetwork = {
    classTitle: 'blue',
    title: 'Novedades',
    classSubtitle: 'small',
    subtitle: 'Entérate de lo que está pasando en Autovía Neiva - Girardot',
  };

  dataHeaderWeatherCondition = {
    classTitle: 'blue',
    title: 'Estado del tiempo',
    classSubtitle: 'small',
    subtitle: 'Entérate del estado del tiempo con Autovía Neiva - Girardot',
  };

}
