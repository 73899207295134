<!-- Footer -->
<footer class="content footer">
  <div class="content footer2 py-3 d-flex flex-column">
    <div class="d-flex footer-info">
      <div class="col-md-12 col-sm-12 col-lg-4 d-flex align-items-center justify-content-center">
        <!-- Content -->
        <image-link [imgInfo]="imgInfoV"></image-link>
      </div>
      <hr class="clearfix w-100 d-md-none pb-3" />
      <div class="col-lg-4 col-sm-12 col-md-6 my-3 lists-containers px-4" *ngFor="let links of linksData">
        <ul class="list-unstyled" *ngFor="let link of links">
          <label-block [info]="link" *ngIf="!link?.sede"></label-block>
          <label-block-date-footer [info]="link" *ngIf="link?.sede"></label-block-date-footer>
        </ul>
      </div>
    </div>
    <div class="iconos_footer_dos d-flex flex-wrap d-block">
      <image-link class="custom-icons-screen" *ngFor="let icon of iconList" [imgInfo]="icon"></image-link>
    </div>
  </div>

  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="iconos_footer d-flex flex-wrap">
    <image-link *ngFor="let icon of ccIcons" [imgInfo]="icon"></image-link>
  </div>
</footer>