import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//imports atomic components
import { LabelData } from './atoms.component';
import { LinkData } from './atoms.component';
import { ImageData } from './atoms.component';
import { ButtonInfo } from './atoms.component';
import { InputComponent } from './atoms.component';
import { textareaComponent } from './atoms.component';
import { inputCheck } from './atoms.component';
import { RouterModule } from '@angular/router'; // Asegúrate de importar RouterModule

@NgModule({
  declarations: [
    LabelData,
    LinkData,
    ImageData,
    ButtonInfo,
    InputComponent,
    textareaComponent,
    inputCheck,
  ],
  imports: [ReactiveFormsModule, FormsModule, RouterModule],
  exports: [
    LabelData,
    LinkData,
    ImageData,
    ButtonInfo,
    InputComponent,
    textareaComponent,
    inputCheck,
  ],
  entryComponents: [
    LabelData,
    LinkData,
    ImageData,
    ButtonInfo,
    InputComponent,
    textareaComponent,
    inputCheck,
  ],
})
export class AtomsModule { }
