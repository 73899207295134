import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-accesibility',
  templateUrl: './accesibility.component.html',
  styleUrls: ['./accesibility.component.css'],
})
export class AccesibilityComponent implements OnInit {
  contrastBool = true;
  counter = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    var contrastOn = localStorage.getItem('contrast');
    if (contrastOn && contrastOn === 'false') {
      this.renderer.addClass(this.document.body, 'contrast');
    } else if (contrastOn && contrastOn === 'true') {
      this.renderer.removeClass(this.document.body, 'contrast');
    }
  }

  contrast() {
    this.contrastBool = !this.contrastBool;
    //this.document.body.classList.toggle('contrast');
    //this.renderer.addClass(this.document.body, 'contrast');
    localStorage.removeItem('contrast');
    localStorage.setItem('contrast', this.contrastBool.toString());
    //this.document.body.classList.toggle('contrast');
    this.ngOnInit();
  }

  fontSize10px = 10;
  fontSize12px = 12;
  fontSize14px = 14;
  fontSize20px = 20;
  fontSize25px = 25;

  fontSize0_7rem = 0.7;
  fontSize0_8rem = 0.8;
  fontSize1_0rem = 1.0;
  fontSize1_7rem = 1.7;
  fontSize3_5rem = 3.5;

  @ViewChild('para', { static: true }) para: ElementRef | undefined;

  changeFont(operator: string) {
    if (operator === '+' && this.counter < 3) {
      this.counter++;
    }
    if (operator === '-' && this.counter > -3) {
      this.counter--;
    }

    document.getElementsByTagName('body')[0].style.fontSize = `${
      this.fontSize1_0rem + this.counter / 10
    }rem`;

    Array.from(
      document.getElementsByClassName(
        'card-date'
      ) as HTMLCollectionOf<HTMLElement>
    ).forEach((element) => {
      element.style.fontSize = `${this.fontSize10px + this.counter}px`;
    });

    document
      .querySelectorAll<HTMLElement>(
        '.card-text,.text-small,.card-text,.news-button .btn'
      )
      .forEach((element) => {
        element.style.fontSize = `${this.fontSize12px + this.counter}px`;
      });

    document
      .querySelectorAll<HTMLElement>(
        '.card-title, .card-title1, .text-info.white'
      )
      .forEach((element) => {
        element.style.fontSize = `${this.fontSize14px + this.counter}px`;
      });

    document
      .querySelectorAll<HTMLElement>('.title-info,.title-form')
      .forEach((element) => {
        element.style.fontSize = `${this.fontSize20px + this.counter}px`;
      });

    document
      .querySelectorAll<HTMLElement>(
        '.blue-bold-font, .mat-tab-label, .white-bold'
      )
      .forEach((element) => {
        element.style.fontSize = `${this.fontSize25px + this.counter}px`;
      });

    Array.from(
      document.getElementsByClassName('error') as HTMLCollectionOf<HTMLElement>
    ).forEach((element) => {
      element.style.fontSize = `${this.fontSize0_7rem + this.counter / 10}rem`;
    });

    Array.from(
      document.getElementsByClassName(
        'text-img'
      ) as HTMLCollectionOf<HTMLElement>
    ).forEach((element) => {
      element.style.fontSize = `${this.fontSize0_8rem + this.counter / 10}rem`;
    });

    Array.from(
      document.getElementsByClassName(
        'tittle-responsive'
      ) as HTMLCollectionOf<HTMLElement>
    ).forEach((element) => {
      element.style.fontSize = `${this.fontSize1_0rem + this.counter / 10}rem`;
    });

    Array.from(
      document.getElementsByClassName(
        'white-bold-font'
      ) as HTMLCollectionOf<HTMLElement>
    ).forEach((element) => {
      element.style.fontSize = `${this.fontSize1_7rem + this.counter / 10}rem`;
    });

    Array.from(
      document.getElementsByClassName(
        'construccion-tittle'
      ) as HTMLCollectionOf<HTMLElement>
    ).forEach((element) => {
      element.style.fontSize = `${this.fontSize3_5rem + this.counter / 10}rem`;
    });
  }
}
