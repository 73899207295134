<div class="news">
  <section-header [data]="dataHeader"></section-header>
  <div class="card-container d-flex flex-row col-md-12 col-sm-12 justify-content-center">
    <a target="_blank" *ngFor="let card of cardsData" [routerLink]="['/noticias', card.id]">
      <news-card class="no-margin" [cardInfo]="card"></news-card>
    </a>
  </div>
  <div class="news-button d-flex justify-content-end">
    <a [routerLink]="['/noticias']" class="btn">Ver más noticias</a>
  </div>
</div>
<div class="news">
  <section-header [data]="dataHeaderWeatherCondition"></section-header>
  <div class="row mt-4 mb-4 ml-4 mr-4">
    <div *ngFor="let weather of dataGetWeather"
      class="card-container d-flex flex-row col-md-4 col-sm-4 justify-content-center mt-3">
      <app-weather-condition [name]="weather.name" [iat]="weather.iat" [ion]="weather.ion" [appid]="weather.appid"
        [description]="weather.description"></app-weather-condition>
    </div>
  </div>
</div>
<div class="news">
  <section-header [data]="dataHeaderSocialNetwork"></section-header>
  <div class="row mt-5 mb-5 d-flex justify-content-center">
    <div class="mt-3 card-container d-flex flex-row col-md-4 col-sm-6 justify-content-center"
      *ngIf="facebookEnabled?.activo">
      <iframe name="f16afff68e86433b5" width="1000px" height="1000px" data-testid="fb:page Facebook Social Plugin"
        title="fb:page Facebook Social Plugin" allowfullscreen="true" scrolling="no" allow="encrypted-media"
        src="https://www.facebook.com/v2.5/plugins/page.php?adapt_container_width=true&amp;app_id=425960334187885&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dfc029e3bbcdfd8220%26domain%3Dwww.autovia.com.co%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.autovia.com.co%252Ffddd32dc8cd81e28f%26relation%3Dparent.parent&amp;container_width=370&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FAutovia-Neiva-Girardot-SAS-972422292852864%2F&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline"
        style="border: none; visibility: visible; width: 340px; height: 500px;" class=""></iframe>
    </div>
    <div class="mt-3 card-container-instagram d-flex flex-row col-md-4 col-sm-6 justify-content-center"
      *ngIf="instagramEnabled?.activo">
      <app-instagram-feed></app-instagram-feed>
    </div>
    <div class="mt-3 card-container d-flex flex-row col-md-4 col-sm-6 justify-content-center"
      *ngIf="twitterEnabled?.activo">
      <iframe name="" width="1000px" height="1000px" data-testid="fb:page Facebook Social Plugin"
        title="Twitter Timeline" allowfullscreen="true" scrolling="no" allow="encrypted-media"
        src="https://syndication.twitter.com/srv/timeline-profile/screen-name/AutoviaNG?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;frame=false&amp;hideBorder=false&amp;hideFooter=false&amp;hideHeader=false&amp;hideScrollBar=false&amp;lang=es&amp;maxHeight=600px&amp;origin=https%3A%2F%2Fwww.autovia.com.co%2F&amp;sessionId=5f0f62d77f8cb70e910783fb3e3f6010015c404e&amp;showHeader=true&amp;showReplies=false&amp;transparent=false&amp;widgetsVersion=2615f7e52b7e0%3A1702314776716"
        style="border: none; visibility: visible; width: 340px; height: 500px;" class=""></iframe>
    </div>
  </div>
</div>
<div class="partners-container d-flex flex-wrap justify-content-around">
  <image-link *ngFor="let logo of partners" [imgInfo]="logo"></image-link>
</div>