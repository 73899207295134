import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import { ChangeDataService } from 'src/app/Services/ChangeData/changeData.service';

export interface DataAcordion {
  class: string;
  title: string;
  itemClass: string;
  detail: {};
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent {
  @Input() sidebarLinks: any[] = [];
  @Input() selected: Number = 0;

  numData: any;
  newData: any = [];

  links = [
    ['/institucional', ''],
    ['/nosotros', ''],
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDataService: ChangeDataService
  ) {}

  showFiller = false;
  openBar = false;
  menuState: string = 'out';
  menuStateMovil: string = 'in';

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  changeData(e: Event) {
    const target = e.currentTarget as Element;

    this.selected = Number(target.id);

    this.changeDataService.onChangeIndex(target.id);
    this.changeDataService.onChangeTittle(target.getAttribute('name'));
  }

  async toggleNavbar() {
    this.openBar = !this.openBar;
  }
}
