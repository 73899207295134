import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/Api/api.service';

@Component({
  selector: 'app-weather-condition',
  templateUrl: './weather-condition.component.html',
  styleUrls: ['./weather-condition.component.css']
})
export class WeatherConditionComponent implements OnInit {
  @Input() name: string = "";
  @Input() description: string = "";
  @Input() iat: string = "";
  @Input() ion: string = "";
  @Input() appid: string = "";

  weatherData: any[] = [];


  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
    this.getWeatherConditions(this.iat, this.ion, this.appid);
  }

  private getWeatherConditions(
    iat: string,
    ion: string,
    appid: string
  ) {
    this._apiService.getWeatherCondition(`weather?lat=${iat}&lon=${ion}&appid=${appid}&lang=es`).then((data) => {
      const dataMap = {
        ...data,
        main: {
          ...data.main,
          temp: `${(Number(data.main.temp) - 273.15).toFixed(2)}`,
        },
        weather: data.weather.map((itm: any) => {

          if (itm.description) {
            const firstLetterCapital = `${itm.description.slice(0, 1)}`.toUpperCase();
            return {
              ...itm,
              description: `${firstLetterCapital}${itm.description.slice(1)}`
            }
          }
          return { ...itm };
        }),
      };

      this.weatherData = [
        ...this.weatherData,
        dataMap

      ];
    });
  }
}
