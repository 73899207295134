import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//Imports for angular material
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

//Imports for atomic components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NewsComponent } from './news/news.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CarruselImgsComponent } from './carrusel-imgs/carrusel-imgs.component';
import { CarruselIconosComponent } from './carrusel-iconos/carrusel-iconos.component';
import { AccordionPaginatorComponent } from './accordion-paginator/accordion-paginator.component';
import { FormComponent } from './form/form.component';
import { DataTableComponent } from './data-table/data-table.component';
import { PanelDataComponent } from './panel-data/panel-data.component';
//Imports for modules
import { AtomsModule } from '../00-atoms/atoms.module';
import { MoleculesModule } from '../01-molecules/molecules.module';
import { DataPublicacionesComponent } from './data-publicaciones/data-publicaciones.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from 'src/app/Services/Api/api.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { DataFormatosComponent } from './data-formatos/data-formatos.component';
import { RouterModule } from '@angular/router';
import { ConstructionComponent } from '../construction/construction.component';
import { WeatherConditionComponent } from './weather-condition/weather-condition.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { DataImportesComponent } from './data-importes/data-importes.component';
import { InstagramFeedComponent } from './instagram-feed/instagram-feed.component';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NewsComponent,
    SidebarComponent,
    CarruselImgsComponent,
    CarruselIconosComponent,
    AccordionPaginatorComponent,
    FormComponent,
    DataTableComponent,
    PanelDataComponent,
    DataPublicacionesComponent,
    DataFormatosComponent,
    ConstructionComponent,
    WeatherConditionComponent,
    ModalInfoComponent,
    DataImportesComponent,
    InstagramFeedComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    AtomsModule,
    MoleculesModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NewsComponent,
    SidebarComponent,
    CarruselImgsComponent,
    CarruselIconosComponent,
    AccordionPaginatorComponent,
    FormComponent,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatIconModule,
    MatTabsModule,
    DataTableComponent,
    PanelDataComponent,
    DataPublicacionesComponent,
    DataImportesComponent,
    DataFormatosComponent,
    ConstructionComponent,
    MatProgressSpinnerModule,
  ],
  entryComponents: [
    HeaderComponent,
    FooterComponent,
    NewsComponent,
    SidebarComponent,
    CarruselImgsComponent,
    CarruselIconosComponent,
    AccordionPaginatorComponent,
    FormComponent,
    DataTableComponent,
    PanelDataComponent,
    DataPublicacionesComponent,
    DataImportesComponent,
    DataFormatosComponent,
    ConstructionComponent,
  ],
  providers: [ApiService],
})
export class OrganismsModule {}
