<ul class="sidebar">
  <li
    *ngFor="let link of sidebarLinks"
    class="sidebar-link"
    [ngClass]="{ selected: link.id === selected }"
  >
    <a [id]="link.id" [name]="link.tittle" (click)="changeData($event)"
      ><div class="icon"><i [ngClass]="link.icon"></i></div>
      <span>{{ link.tittle }}</span></a
    >
  </li>
</ul>
