import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  get(endpoint: string): Promise<any> {
    return this.http
      .get<any>(environment.backendURL + `/${endpoint}/`)
      .pipe(retry(1))
      .toPromise();
  }

  getWeatherCondition(endpoint: string): Promise<any> {
    return this.http
      .get<any>(environment.weatherConditionURL + `/${endpoint}`)
      .pipe(retry(1))
      .toPromise();
  }

  post(endpoint: string, entity: any) {
    return this.http
      .post<any>(
        environment.backendURL + `/${endpoint}/`,
        JSON.stringify(entity),
        this.httpOptions
      )
      .toPromise();
  }
}
