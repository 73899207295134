import {
  Component,
  OnInit,
  ElementRef,
  HostListener,
  Directive,
} from '@angular/core';
import { ResizeService } from 'src/app/Services/Resize-service/resize.service';
import { delay } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/Api/api.service';

@Component({
  selector: 'app-carrusel-iconos',
  templateUrl: './carrusel-iconos.component.html',
  styleUrls: ['./carrusel-iconos.component.css'],
})
export class CarruselIconosComponent implements OnInit {
  currentSize: any;
  size: any;

  serviciosExternos: any[] = [];

  constructor(
    private elementRef: ElementRef,
    private resizeSvc: ResizeService,
    private _apiService: ApiService
  ) {
    this.resizeSvc.onResize$.pipe(delay(0)).subscribe((x) => {
      this.size = x;
    });
  }

  inicio = 0;
  fin = 6;
  cantidadMostrar = 6;

  showIcons: any[] = [];

  ngOnInit() {
    this._apiService.get('servicio-externo').then((data) => {
      this.serviciosExternos = [...data.tramitesExternos];
      this.showIcons = [...this.serviciosExternos.slice(this.inicio, this.fin)];
    });
  }

  nextSlide() {
    if (this.fin < this.serviciosExternos.length) {
      this.fin++;
      this.inicio++;
    }

    this.ngOnInit();
  }
  prevSlide() {
    if (this.inicio <= 0) {
      this.inicio = 0;
      this.fin = this.cantidadMostrar;
    } else {
      this.inicio--;
      this.fin--;
    }
    this.ngOnInit();
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.currentSize = window.innerWidth;
    if (this.currentSize < 768) {
      this.cantidadMostrar = 2;
    } else if (this.currentSize >= 768 && this.currentSize < 1024) {
      this.cantidadMostrar = 4;
    } else if (this.currentSize >= 1024) {
      this.cantidadMostrar = 6;
    }
    this.inicio = 0;
    this.fin = this.cantidadMostrar;
    this.ngOnInit();
  }

  public esIconoFA(icono: string) {
    return icono.split(' ')[0] === 'fa';
  }
}
