import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'under-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.css'],
})
export class ConstructionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
