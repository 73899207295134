import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  navbarOpen = false;

  imgInfoV = {
    class: 'img',
    src: '../../../../assets/images/Btn_Autovia.png',
    target: '_self',
    targetURL: '',
    altT: 'Logo',
    heightInput: '',
    widthInput: '130px',
  };

  imgInfoS = {
    src: '../../../../assets/images/Btn_Autovia.png',
    target: '_self',
    targetURL: '',
    altT: 'Logo',
    heightInput: '',
    widthInput: '160px',
    class: '',
  };
  links = [
    ['/institucional', 'Puntos de atención'],
    ['/nosotros', 'Nosotros'],
    ['/estado-via', 'Estado de la Vía'],
    ['/servicios', 'Servicios'],
    ['/importes', 'Importes'],
    // ['/tarifas', 'Tarifas'],
    // ['/picoyplaca', 'Pico y Placa'],
    ['/documento', 'Documento'],
    ['/contacto', 'Contáctanos'],
  ];

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  iconList = [
    {
      src: '../../../../assets/images/iconos/header_1.svg',
      target: '_blank',
      targetURL: 'https://www.facebook.com/people/Autovia-Neiva-Girardot-SAS/100064020514109/',
      altT: 'facebook icon',
      heightInput: '',
      widthInput: '36px',
      class: 'svg-icon',
    },
    {
      src: '../../../../assets/images/iconos/header_2.svg',
      target: '_blank',
      targetURL: 'https://x.com/autoviang?s=11',
      altT: 'twitter icon',
      heightInput: '',
      widthInput: '36px',
      class: 'svg-icon',
    },
    {
      src: '../../../../assets/images/iconos/header_3.svg',
      target: '_blank',
      targetURL: 'https://www.instagram.com/autovianeivagirardot17?igsh=MXVtNXQxcnUxNHp6eA==',
      altT: 'instagram icon',
      heightInput: '',
      widthInput: '36px',
      class: 'svg-icon',
    },
  ];
}
