import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/Api/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor(private _apiService: ApiService) { }
  async ngOnInit() {
    await this._apiService.get('sede').then((data) => {
      this.linksData.push([
        ...data.sedes.map((sede: any) => ({
          sede: true,
          title: sede.nombre,
          class1: 'blue-bold',
          title2: 'Dirección: ',
          class2: 'negrilla mr-1',
          dataTitle2: sede.direccion,
          additionalTittle: sede.horarios,
          additionalData: '',
        })),
      ]);
    });

    await this._apiService.get('contacto').then((data) => {
      this.linksData.push([
        {
          title: 'Teléfonos',
          class1: 'blue-bold',
          title2: '',
          class2: '',
          dataTitle2: data.contactos
            .filter((contacto: any) => contacto.tipoContacto === '0')
            .map((contacto: any) => contacto.valor)
            .join(', '),
          additionalTittle: '',
          additionalData: '',
        },
        {
          title: 'Correos',
          class1: 'blue-bold',
          title2: '',
          class2: '',
          dataTitle2: data.contactos
            .filter((contacto: any) => contacto.tipoContacto === '1')
            .map((contacto: any) => contacto.valor)
            .join(', '),
          additionalTittle: '',
          additionalData: '',
        },
      ]);
    });
  }

  imgInfoV = {
    src: '../../../../assets/images/Btn_Autovia_.png',
    target: '_self',
    targetURL: '',
    altT: 'Logo',
    heightInput: '',
    widthInput: '200px',
  };

  iconList = [
    {
      src: '../../../../assets/images/iconos/footer_4.svg',
      target: '_blank',
      targetURL: 'https://www.facebook.com/people/Autovia-Neiva-Girardot-SAS/100064020514109/',
      altT: 'facebook icon',
      heightInput: '',
      widthInput: '36px',
      class: 'svg-icon',
    },
    {
      src: '../../../../assets/images/iconos/footer_5.svg',
      target: '_blank',
      targetURL: 'https://x.com/autoviang?s=11',
      altT: 'twitter icon',
      heightInput: '',
      widthInput: '36px',
      class: 'svg-icon',
    },
    {
      src: '../../../../assets/images/iconos/footer_6.svg',
      target: '_blank',
      targetURL: 'https://www.instagram.com/autovianeivagirardot17?igsh=MXVtNXQxcnUxNHp6eA==',
      altT: 'instagram icon',
      heightInput: '',
      widthInput: '36px',
      class: 'svg-icon',
    },
  ];
  ccIcons = [
    {
      src: '../../../../assets/images/iconos/footer_2.svg',
      target: '_blank',
      targetURL: 'https://creativecommons.org/licenses/by-nc/4.0/',
      altT: '',
      heightInput: '',
      widthInput: '32px',
      class: 'svg-icon',
    },
    {
      src: '../../../../assets/images/iconos/footer_1.svg',
      target: '_blank',
      targetURL: 'https://creativecommons.org/licenses/by-nc/4.0/',
      altT: '',
      heightInput: '',
      widthInput: '32px',
      class: 'svg-icon',
    },
    {
      src: '../../../../assets/images/iconos/footer_3.svg',
      target: '_blank',
      targetURL: 'https://creativecommons.org/licenses/by-nc/4.0/',
      altT: 'instagram icon',
      heightInput: '',
      widthInput: '32px',
      class: 'svg-icon',
    },
  ];

  linksData: any[] = [];
}
